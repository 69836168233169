export const activities = [
	{
		name: 'Name Current Activity',
		description: 'Description Current Activity',
		startingDate: '2022-01-13T09:00:00',
		endingDate: '9999-12-31T23:59:59',
	},
	{
		name: 'Name Future Activity',
		description: '',
		startingDate: '8888-12-31T23:59:59',
		endingDate: '9999-12-31T23:59:59',
	},
];
